import { Form, Field, Picker, Popup, Area, Icon, Stepper, TreeSelect, RadioGroup, Radio } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
export default {
    components: {
        Navbar: Navbar,
        Share: Share,
        Loading: Loading,
        [Form.name]: Form,
        [Field.name]: Field,
        [Picker.name]: Picker,
        [Popup.name]: Popup,
        [Area.name]: Area,
        [Icon.name]: Icon,
        [Stepper.name]: Stepper,
        [TreeSelect.name]: TreeSelect,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio
    },
    data () {
        return {
            loadingShow: false,
            locationShow: false,
            personCode: '',
            wallet: {},
            regions: {},
            regionName: '',
            regionShow: false,
            supplier: {},
            jobActiveId: '',
            jobActiveIndex: 1,
            jobChose: { text: '', id: '' },
            job: { text: '按分类选择', id: '' },
            jobs: [{ id: '', text: '全部分类', children: [{ id: '', text: '全部分类' }] }],
            jobShow: false,
            classifys: [{ code: '', name: '不限' }],
            classifyShow: false,
            classifyChose: {},
            salaryTypes: [
                { value: 'TIM', text: '按小时结算' },
                { value: 'DAY', text: '按天结算' },
                { value: 'MON', text: '按月结算' },
                { value: 'YER', text: '按年结算' },
                { value: 'FQC', text: '按次结算' },
                { value: 'MET', text: '按米结算' },
                { value: 'SMT', text: '按平米结算' }
            ],
            salaryTypeChose: { value: 'MON', text: '按月结算' },
            salaryTypeShow: false,
            salaryMode: '1',
            qualifys: [{ code: '', name: '不限' }],
            qualifyShow: false,
            qualifyChose: {},
            genders: [{ value: '', text: '不限' }, { value: 'MAL', text: '男' }, { value: 'FML', text: '女' }],
            genderShow: false,
            genderChose: {},
            regionDefault: '610100',
            form: {
                base: {
                    job: '',
                    region: '',
                    name: '',
                    address: '',
                    loaction: '',
                    advantage: '',
                    classify: '',
                    salaryMin: '3000',
                    salaryMax: '5000',
                    salaryType: 'MON',
                    count: '10',
                    periodType: 'LNG',
                    periodMin: '5',
                    periodMax: '10',
                    description: ''
                },
                condition: {
                    gender: '',
                    qualify: '',
                    ageMin: '18',
                    ageMax: '60'
                }
            }
        }
    },
    mounted () {
        var rd = window.sessionStorage.getItem('hire_publish_create_reload')
        if (rd === undefined || rd === null || rd === '') {
            window.location.reload('#page')
            window.sessionStorage.setItem('hire_publish_create_reload', 'true')
        }
        var query = this.$route.query
        var code = query.personCode
        if (code !== '' && code !== undefined && code !== null) {
            this.personCode = code
        }
        var that = this
        window.addEventListener('message', function (event) {
            // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
            var loc = event.data
            if (loc && loc.module === 'locationPicker') {
                // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
                that.form.base.address = loc.poiaddress
                console.log(that.form.base.address)
                that.form.base.location = loc.latlng
            }
        }, false)
        this.retrieveRegions()
        this.retrieveJobTree()
        this.retrieveQualifyOption()
        this.retrieveClassifyList()
        this.retrieveWallet()
        this.retrieveUserSupplier()
        this.$refs.share.default('main')
    },
    methods: {
        async retrieveJobTree () {
            var pd = { groupUpper: '4' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/data/job/retrieveJobTree', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.jobs = res.data
            }
        },
        async retrieveUserSupplier () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user }
            const { data: res } = await this.$http.post(this.UMS_URL + '/supply/supplier/retrieveUserSupplier', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.supplier = res.data
            }
        },
        clickJobNav (index) {
            this.jobActiveIndex = index
        },
        clickJobItem (item) {
            this.jobChose = item
            this.form.base.job = item.id
            this.jobShow = false
            this.buildName()
        },
        confirmGender (item) {
            this.form.condition.gender = item.value
            this.genderChose = item
            this.genderShow = false
        },
        confirmQualify (item) {
            this.form.condition.qualify = item.value
            this.qualifyChose = item
            this.qualifyShow = false
        },
        confirmRegion (values) {
            this.regionName = values
                .filter((item) => !!item)
                .map((item) => item.name)
                .join('-')
            this.form.base.region = values[1].code
            this.regionShow = false
            this.buildName()
        },
        confirmSalaryType (item) {
            this.salaryTypeChose = item
            this.form.base.salaryType = item.code
            this.salaryTypeShow = false
        },
        confirmClassify (item) {
            this.form.base.classify = item.code
            this.classifyChose = item
            this.classifyShow = false
        },
        salaryModeChange (item) {
            if (item === '3') {
                this.form.base.salaryMin = 0
                this.form.base.salaryMax = 0
            } else if (item === '2') {
                this.form.base.salaryMax = 0
            }
        },
        async retrieveRegions () {
            var pd = { level: 3 }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/region/retrieveLevelRegion', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.regions.province_list = res.data.one
                this.regions.city_list = res.data.two
                this.regions.county_list = res.data.three
            }
        },
        formSubmit () {
            this.$refs.form.submit()
        },
        async submit () {
            var conditions = [
                { key: 'PSN_BAS_GENDER', content: this.form.condition.gender },
                { key: 'PSN_BAS_QUALIFY', content: this.form.condition.qualify },
                { key: 'PSN_BAS_AGE', content: { ageMax: this.form.condition.ageMax, ageMin: this.form.condition.ageMin } }
            ]
            var merchant = window.sessionStorage.getItem(this.SESSION_MERCHANT)
            var pd = {
                jobCode: this.form.base.job,
                count: this.form.base.count,
                classifyCode: this.form.base.classify,
                name: this.form.base.name,
                advantage: this.form.base.advantage,
                supplierCode: this.supplier.code,
                merchantCode: merchant,
                address: this.form.base.address,
                description: this.form.base.description,
                periodType: this.form.base.periodType,
                periodMax: this.form.base.periodMax,
                periodMin: this.form.base.periodMin,
                regionCode: this.form.base.region,
                salaryMax: this.form.base.salaryMax,
                salaryMin: this.form.base.salaryMin,
                salaryType: this.form.base.salaryType,
                location: JSON.stringify(this.form.base.location),
                conditions: JSON.stringify(conditions)
            }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskRLS/createTask', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.back()
            }
        },
        async buy (code) {
            var pd = { taskCode: code }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskAPC/buy', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.back()
            }
        },
        async retrieveAttr () {
        },
        async retrieveRegionName () {
        },
        async retrieveQualifyOption () {
            var pd = { key: 'QUALIFY_TYPE' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.qualifys = this.qualifys.concat(res.data.item)
            }
        },
        async retrieveClassifyList () {
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/classify/retrieveClassifySelect')
            if (res.status === '200') {
                this.classifys = res.data
            }
        },
        async retrieveWallet () {
            var merchant = window.sessionStorage.getItem(this.SESSION_MERCHANT)
            var pd = { merchantCode: merchant }
            const { data: res } = await this.$http.post(this.UMS_URL + '/business/wallet/retrieveWallet', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.wallet = res.data
            }
        },
        buildName () {
            this.form.base.name = this.regionName + ' | ' + this.jobChose.text
        },
        location () {
            this.locationShow = true
        },
        locationCancel () {
            this.form.base.address = ''
            this.form.base.location = ''
            this.locationShow = false
        },
        locationConfirm () {
            this.locationShow = false
        },
        back () {
            this.$router.go(-1)
        }
    }
}

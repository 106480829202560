<template>
  <div class="page">
    <Navbar title="招聘发布" type="SMP" />
    <van-form @submit="submit" class="form" ref="form">
      <div class="info">
        <van-row class="title">
          <van-col span="12" class="text"
            ><van-icon name="notes-o" /> 基础信息</van-col
          >
        </van-row>
        <van-field
          v-model="regionName"
          label="工作城市"
          label-width="6.2em"
          readonly
          clickable
          placeholder="选择城市"
          input-align="center"
          :required="true"
          :rules="[
            {
              required: true,
              message: '选择城市',
            },
          ]"
          @click="regionShow = true"
        />
        <van-popup :show="regionShow" position="bottom">
          <van-area
            :area-list="regions"
            :columns-num="3"
            @confirm="confirmRegion"
            @cancel="regionShow = false"
          />
        </van-popup>
        <van-field
          v-model="form.base.address"
          label="工作地址"
          label-width="6.2em"
          placeholder="请点击选择工作地址"
          readonly
          @click="location"
        ></van-field>
        <van-popup :show="locationShow" position="bottom">
          <iframe
            id="mapPage"
            class="location"
            width="100%"
            height="100%"
            frameborder="0"
            src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=RXSBZ-LRKK3-TPO32-RYEX5-IORC3-FPBMD&referer=myapp"
          >
          </iframe>
          <van-row class="location-btn">
            <van-col span="12">
              <van-button
                :color="COLOR"
                size="small"
                icon="passed"
                @click="locationCancel"
              >
                取消选择
              </van-button></van-col
            >
            <van-col span="12"
              ><van-button
                :color="COLOR_S1"
                size="small"
                icon="passed"
                @click="locationConfirm"
              >
                确认选择
              </van-button></van-col
            >
          </van-row>
        </van-popup>
        <van-field
          v-model="jobChose.text"
          label="工种选择"
          label-width="6.2em"
          readonly
          clickable
          placeholder="选择工种"
          input-align="center"
          :required="true"
          :rules="[
            {
              required: true,
              message: '请选择工种',
            },
          ]"
          @click="jobShow = true"
        />
        <van-popup :show="jobShow" position="bottom">
          <van-row class="close">
            <van-col :span="24"
              ><van-icon name="close" size="25" @click="jobShow = false" />
            </van-col>
          </van-row>
          <van-tree-select
            :active-id="jobActiveId"
            :main-active-index="jobActiveIndex"
            :items="jobs"
            @click-nav="clickJobNav"
            @click-item="clickJobItem"
          />
        </van-popup>
        <van-field
          v-model="form.base.name"
          rows="2"
          autosize
          label="招聘标题"
          label-width="6.2em"
          type="textarea"
          maxlength="100"
          placeholder="请填写招聘标题"
          :required="true"
          :rules="[
            {
              required: true,
              message: '请填写招聘标题',
            },
          ]"
          show-word-limit
        />
        <van-field
          v-model="form.base.advantage"
          label="优势"
          label-width="6.2em"
          placeholder="请填写招聘优势"
          :rules="[{ required: true, message: '请填写招聘优势' }]"
        ></van-field>
        <van-field
          v-model="classifyChose.name"
          label="所属分类"
          label-width="6.2em"
          readonly
          clickable
          placeholder="请选择所属分类"
          input-align="center"
          :required="true"
          :rules="[
            {
              required: true,
              message: '请选择所属分类',
            },
          ]"
          @click="classifyShow = true"
        />
        <van-popup :show="classifyShow" position="bottom">
          <van-picker
            :columns="classifys"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmClassify"
            @cancel="classifyShow = false"
          />
        </van-popup>
        <van-field
          label="薪资模式"
          label-width="6.2em"
          placeholder="薪资模式"
          :rules="[{ required: true, message: '请填写用户名' }]"
          ><template #input>
            <van-radio-group
              v-model="salaryMode"
              :checked-color="COLOR_M"
              direction="horizontal"
              @change="salaryModeChange"
            >
              <van-radio name="1">范围</van-radio>
              <van-radio name="2">固定</van-radio>
              <van-radio name="3">面议</van-radio>
            </van-radio-group></template
          ></van-field
        >
        <van-field
          v-if="salaryMode === '1'"
          v-model="form.base.salaryMin"
          label="最低薪资"
          label-width="6.2em"
          placeholder="请填写最低薪资"
          :rules="[{ required: true, message: '请填写最低薪资' }]"
        ></van-field>
        <van-field
          v-if="salaryMode === '1'"
          v-model="form.base.salaryMax"
          label="最高薪资"
          label-width="6.2em"
          placeholder="请填写最高薪资"
          :rules="[{ required: true, message: '请填写最高薪资' }]"
        ></van-field>
        <van-field
          v-if="salaryMode === '2'"
          v-model="form.base.salaryMin"
          label="薪资金额"
          label-width="6.2em"
          placeholder="请填写薪资金额"
          :rules="[{ required: true, message: '请填写薪资金额' }]"
        ></van-field>

        <van-field
          v-model="salaryTypeChose.text"
          label="结算方式"
          label-width="6.2em"
          readonly
          clickable
          placeholder="选择结算方式"
          input-align="center"
          :required="true"
          :rules="[
            {
              required: true,
              message: '选择结算方式',
            },
          ]"
          @click="salaryTypeShow = true"
        />
        <van-popup :show="salaryTypeShow" position="bottom">
          <van-picker
            :columns="salaryTypes"
            :columns-field-names="{
              id: 'value',
            }"
            @confirm="confirmSalaryType"
            @cancel="salaryTypeShow = false"
          />
        </van-popup>
        <van-field
          v-model="form.base.count"
          label="人数"
          label-width="6.2em"
          placeholder="人数"
          :required="true"
        >
          <template #input>
            <van-stepper v-model="form.base.count" />
          </template>
        </van-field>
        <van-field
          label="工作类型"
          label-width="6.2em"
          placeholder="工作类型"
          :rules="[{ required: true, message: '请选择工作类型' }]"
          ><template #input>
            <van-radio-group
              v-model="form.base.periodType"
              :checked-color="COLOR_M"
              direction="horizontal"
            >
              <van-radio name="LNG">长期</van-radio>
              <van-radio name="SOT">短期</van-radio>
              <van-radio name="PRT">兼职</van-radio>
            </van-radio-group></template
          ></van-field
        >
        <van-field
          label="工期"
          label-width="6.2em"
          v-if="form.base.periodType === 'SOT'"
          ><template #input>
            <van-stepper
              v-model="form.base.periodMin"
            />&nbsp;到&nbsp;<van-stepper v-model="form.base.periodMax" />
            天</template
          ></van-field
        >
        <van-field
          v-model="form.base.description"
          rows="3"
          autosize
          label="工作描述"
          label-width="6.2em"
          type="textarea"
          maxlength="500"
          placeholder="请填写岗位工作、待遇等信息，例如：从事水泥搅拌工作，包吃包住，工资日结"
          :required="true"
          :rules="[
            {
              required: true,
              message: '请填写工作描述',
            },
          ]"
          show-word-limit
        />
      </div>
      <div class="condition">
        <van-row class="title">
          <van-col span="12" class="text"
            ><van-icon name="notes-o" /> 条件要求</van-col
          >
        </van-row>
        <van-field
          v-model="genderChose.text"
          label="性别"
          label-width="6.2em"
          readonly
          clickable
          placeholder="请选择性别"
          input-align="center"
          @click="genderShow = true"
        />
        <van-popup :show="genderShow" position="bottom">
          <van-picker
            :columns="genders"
            @confirm="confirmGender"
            @cancel="genderShow = false"
          />
        </van-popup>
        <van-field
          v-model="qualifyChose.name"
          label="最低学历"
          label-width="6.2em"
          readonly
          clickable
          placeholder="请选择最低学历"
          input-align="center"
          @click="qualifyShow = true"
        />
        <van-popup :show="qualifyShow" position="bottom">
          <van-picker
            :columns="qualifys"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="confirmQualify"
            @cancel="qualifyShow = false"
          />
        </van-popup>
        <van-field name="stepper" label="年龄要求" label-width="6.2em">
          <template #input>
            <van-stepper
              v-model="form.condition.ageMin"
            />&nbsp;到&nbsp;<van-stepper v-model="form.condition.ageMax" />岁
          </template>
        </van-field>
      </div>
    </van-form>
    <div class="operate">
      <van-row>
        <van-col span="16" class="price">
          <span>剩余金豆：</span
          ><span class="number color-s2">{{ wallet.beanBalance }}个</span
          ><span class="color-s2">(招聘消耗10个金豆)</span></van-col
        >
        <van-col span="8">
          <van-button
            :color="COLOR"
            size="small"
            icon="passed"
            @click="formSubmit"
          >
            开始招聘
          </van-button>
        </van-col>
      </van-row>
    </div>
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import Create from './Create.js'
export default {
  ...Create
}
</script>
<style lang="less" scoped>
@import './Create.less';
</style>
